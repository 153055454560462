import {useEffect} from 'react'
import * as data from './data'

export default function Callback(props) {
  const dispatch = data.useDispatch()

  useEffect(() => {
    switch (props.type) {
      case 'bet-history': {
        dispatch(data.openBetHistory())
        break
      }
      case 'classic-mode': {
        dispatch(data.openClassicMode())
        break
      }
      case 'game-over': {
        dispatch(data.closeGame())
        break
      }
      case 'limits': {
        dispatch(data.openLimits())
        break
      }
      case 'login': {
        dispatch(data.openLogin())
        break
      }
      case 'wallet': {
        dispatch(data.openWallet())
        break
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn('Unknown callback type:', props.type)
      }
    }
  }, [dispatch, props.type])

  return null
}
